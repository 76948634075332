import moment from 'moment'
import { createSlice } from '@reduxjs/toolkit'

import callFlask from '../callFlask'
import callHasura from '../callHasura'
import { EnterpriseInvoices } from '../graphQlQueries/types/EnterpriseInvoices'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { downloadCsv, requestedImagesCount } from '../../lib/helpers'
import { fetchEnterpriseInvoicesQuery } from '../graphQlQueries/Billing'
import { getConsultationTypeForId } from '../../lib/modalityHelpers'

export interface BillingState {
  hasErrors: boolean
  loading: boolean
  checkoutSessionId?: string
}

const initialState: BillingState = {
  loading: false,
  hasErrors: false,
}

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    createCustomerPortalSuccess: (state, { payload }) => {
      state.loading = false
      state.hasErrors = false
      window.location.href = payload
    },

    createCheckoutSessionSuccess: (state, { payload }) => {
      state.loading = false
      state.hasErrors = false
      state.checkoutSessionId = payload
    },
  },
})

export const {
  setLoading,
  createCheckoutSessionSuccess,
  networkingSuccess,
  networkingFailure,
  createCustomerPortalSuccess,
} = billingSlice.actions

export const billingSelector = (state: any) => state.billing

export default billingSlice.reducer

export function createCheckoutSessionAction(customerId: string, ai: boolean) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result = await callFlask(`/create-checkout-session?id=${customerId}&ai=${ai ? 'true' : 'false'}`, 'POST')

      if (result.id) {
        dispatch(createCheckoutSessionSuccess(result.id))
      } else {
        dispatch(networkingFailure())
      }
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function createCustomerPortalAction(customerId: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result = await callFlask(`/create-customer-portal-session?id=${customerId}`, 'POST')
      dispatch(createCustomerPortalSuccess(result.url))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function downloadInvoicesForEnterpriseAction(accessToken: string, gt: string, lt: string, enterpriseId: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: EnterpriseInvoices = await callHasura(accessToken, fetchEnterpriseInvoicesQuery(gt, lt, enterpriseId))
      const filename = `${gt}-${lt}-invoices.csv`
      const headers = ['completed date,clinic name,patient name,consultation type,specialist name,number of images,amount']
      const rows = headers.concat(
        ...result.consultations.map((c) =>
          [
            moment(c.completed_at).format('M/DD/YY'),
            c.case.dicom_server?.organization?.display_name,
            c.case.patient.display_name,
            `${getConsultationTypeForId(c.consultation_type_id)?.consultationType.replace('DX', 'X-ray')} consultation`,
            c.receiving_vet?.display_name,
            requestedImagesCount(c),
            `$${c.price_amount}`,
          ]
            .map((s) => String(s)?.replace(/,/g, ''))
            .join(',')
        )
      )

      downloadCsv(filename, rows)
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
