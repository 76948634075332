import gql from 'graphql-tag'

import { user_role_consultation_types_insert_input } from '../../../types/globalTypes'

export const fetchConsultationTypesQuery = () => ({
  query: gql`
    query ConsultationTypes {
      consultation_types(order_by: { display_name: asc }) {
        id
        display_name
      }
    }
  `,
  route: 'consultation_types',
})

export const insertUserRoleConsultationTypeQuery = (object: user_role_consultation_types_insert_input) => ({
  query: gql`
    mutation InsertUserRoleConsultationType($object: user_role_consultation_types_insert_input!) {
      insert_user_role_consultation_types_one(object: $object) {
        id
      }
    }
  `,
  route: 'insert_user_role_consultation_types_one',
  variables: { object },
})

export const deleteUserRoleConsultationTypeQuery = (id: number) => ({
  query: gql`
    mutation DeleteUserRoleConsultationType($id: Int!) {
      delete_user_role_consultation_types_by_pk(id: $id) {
        id
      }
    }
  `,
  route: 'delete_user_role_consultation_types_by_pk',
  variables: { id },
})
