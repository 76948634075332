import { createSlice } from '@reduxjs/toolkit'

import {
  fetchPatientsForOrganizationQuery,
  insertPimsPatientQuery,
  updateCasePatientIdQuery,
  updatePatientQuery,
  updatePatientSpeciesQuery,
} from '../graphQlQueries/Patient'

import callHasura from '../callHasura'
import { PatientParams } from '../../components/request-consult/helpers'
import { Patients_patients } from '../graphQlQueries/types/Patients'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { pims_patients_insert_input } from '../../../types/globalTypes'

export interface PatientsState {
  hasErrors: boolean
  loading: boolean
  patients: Patients_patients[]
}

const initialState: PatientsState = {
  loading: false,
  hasErrors: false,
  patients: [],
}

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchPatientsSuccess: (state, { payload }) => {
      state.patients = payload
      state.loading = false
      state.hasErrors = false
    },
  },
})

export const { setLoading, networkingFailure, networkingSuccess, fetchPatientsSuccess } = patientsSlice.actions

export const patientsSelector = (state: any) => state.patients

export default patientsSlice.reducer

export function fetchPatientsAction(accessToken: string, organization_id: number, input: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const patients = await callHasura(accessToken, fetchPatientsForOrganizationQuery(organization_id, input))

      dispatch(fetchPatientsSuccess(patients))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertPimsPatientAction(accessToken: string, object: pims_patients_insert_input) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertPimsPatientQuery(object))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updatePatientSpeciesAction(accessToken: string, id: number, species: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updatePatientSpeciesQuery(id, species))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateCasePatientIdAction(accessToken: string, id: number, patient_id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateCasePatientIdQuery(id, patient_id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updatePatientAction(accessToken: string, params: PatientParams) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updatePatientQuery(params))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
