import gql from 'graphql-tag'

export const CONDITION_FIELDS = gql`
  fragment condition_fields on conditions {
    id
    display_name
    ml_name
    specialist_notes
    species
    severity
    symptoms_description_short
    ai_notes
    ml_config
    category {
      id
      order_index
      display_name
      region
    }
  }
`
