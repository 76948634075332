import styled from 'styled-components'

import { palette } from '../../lib/cssHelpers'
import { shade } from '../../lib/helpers'

interface Props {
  disabled?: boolean
}

const m = styled.button`
  background-color: ${palette.pink};
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  font-family: inherit;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: 0.3s;
  outline: none;
  font-size: 1em;
  padding: 15px 25px;
  min-width: 200px;
  opacity: ${(p: Props) => (p.disabled ? 0.5 : 1)};
  pointer-events: ${(p: Props) => (p.disabled ? 'none' : 'auto')};
  &:hover {
    background-color: ${shade(palette.pink, -0.1)};
  }
`

const s = styled(m)`
  padding: 10px 15px;
  min-width: 100px;
  font-size: 0.95em;
`

const l = styled(m)`
  padding: 22px 50px;
  min-width: 300px;
  font-size: 1.1em;
`

export default { s, m, l }
