import { css } from 'styled-components'
import CONFIG from '../config'

const palette: any = {
  secondary: '#1bbddf',
  darkBgBlue: '#9bcff9',
  muted: '#7180b8',
  orange: '#f26b35',
  pink: '#fe1cad',
  purple: '#B51F8D',
  red: '#FF0000',
  success: '#28a745',
  textDarkBg: '#b9b9b9',
  gray1: '#e9e9e9',
  gray2: '#b3b3b3',
  gray3: '#999999',
  gray4: '#808080',
  gray5: '#666666',
  gray6: '#4d4d4d',
  gray7: '#343434',
  gray8: '#1d1d1d',
  primary: 'hsl(322, 99%, 50%)',
  primary2: 'hsl(322, 100%, 95%)',
  primary3: 'hsl(321, 99%, 72%)',
  primary4: 'hsl(321, 99%, 61%)',
  primary5: 'hsl(321, 99%, 39%)',
  primary6: 'hsl(321, 99%, 28%)',
  primary7: 'hsl(322, 100%, 17%)',
}

const sizes: any = {
  phone: CONFIG.MOBILE_STYLE_PX_THRESHOLD,
}

interface ThemeInterface {
  primaryColor: string
  primaryColorInverted: string
}

const media = (Object.keys(sizes) as any[]).reduce(
  (acc, label) => {
    acc[label] = (first: any, ...interpolations: any[]) => css`
      @media (max-width: ${sizes[label]}px), (orientation: portrait) {
        ${css(first, ...interpolations)};
      }
    `

    return acc
  },
  // @ts-ignore
  {} as { [key in keyof typeof sizes]: ThemedCssFunction<ThemeInterface> }
)
export { palette, media }
