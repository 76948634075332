import { createSlice } from '@reduxjs/toolkit'

import {
  deleteDicomServerQuery,
  dicomServerSuggestionsQuery,
  fetchDicomServersQuery,
  insertDicomServerQuery,
  updateDicomServerQuery,
} from '../graphQlQueries/DicomServer'

import { DicomServers_dicom_servers } from '../graphQlQueries/types/DicomServers'
import callHasura from '../callHasura'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { DicomServerSuggestions_dicom_servers } from '../graphQlQueries/types/DicomServerSuggestions'

export interface DicomServersState {
  accessToken?: string
  hasErrors: boolean
  loading: boolean
  dicomServers: DicomServers_dicom_servers[]
  dicomServersSuggestions?: DicomServerSuggestions_dicom_servers[]
}

const initialState: DicomServersState = {
  loading: false,
  hasErrors: false,
  dicomServers: [],
}

const dicomServersSlice = createSlice({
  name: 'dicomServers',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchDicomServersSuccess: (state, { payload }) => {
      state.dicomServers = payload
      state.loading = false
      state.hasErrors = false
    },

    fetchDicomServersSuggestionsSuccess: (state, { payload }: { payload: DicomServerSuggestions_dicom_servers[] }) => {
      state.dicomServersSuggestions = payload
    },
  },
})

export const {
  setLoading,
  networkingFailure,
  networkingSuccess,
  fetchDicomServersSuccess,
  fetchDicomServersSuggestionsSuccess,
} = dicomServersSlice.actions

export const dicomServersSelector = (state: any) => state.dicomServers

export default dicomServersSlice.reducer

export function fetchDicomServersAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const dicomServers = await callHasura(accessToken, fetchDicomServersQuery())
      dispatch(fetchDicomServersSuccess(dicomServers))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deleteDicomServerAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteDicomServerQuery(id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertDicomServerAction(accessToken: string, object: any) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertDicomServerQuery(object))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function dicomServerSuggestionsAction(accessToken: string, created_at: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: DicomServerSuggestions_dicom_servers[] = await callHasura(accessToken, dicomServerSuggestionsQuery(created_at))
      dispatch(fetchDicomServersSuggestionsSuccess(result.filter((r) => r.first_study_patient_name)))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateDicomServerAction(accessToken: string, variables: any) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateDicomServerQuery(variables))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
