import uniq from 'lodash/uniq'
import orderBy from 'lodash/orderBy'
import isNumber from 'lodash/isNumber'
import groupBy from 'lodash/groupBy'
import mean from 'lodash/mean'
import moment from 'moment'
import sortBy from 'lodash/sortBy'

import CONFIG from '../config'
import { CompletedConsultation } from '../hasura/slices/consultations'
import { ConsultationsForOrganization_consultations_stat_type } from '../hasura/graphQlQueries/types/ConsultationsForOrganization'
import { Consultations_consultations_stat_type } from '../hasura/graphQlQueries/types/Consultations'
import { EnterpriseBilling_overflow_consults } from '../hasura/graphQlQueries/types/EnterpriseBilling'
import { Modality, getConsultationTypeForModality } from './modalityHelpers'
import { OrganizationBilling_consultations } from '../hasura/graphQlQueries/types/OrganizationBilling'
import { PriceGroups_price_groups_prices } from '../hasura/graphQlQueries/types/PriceGroups'
import { Prices_prices_stat_type } from '../hasura/graphQlQueries/types/Prices'
import { SalesData_organizations_price_group_stat_type } from '../hasura/graphQlQueries/types/SalesData'
import { SoftwareChargeType, softwareChargeTypeForId } from './softwareChargeTypes'
import { consultationTurnaroundTime, formatDuration } from './timeHelpers'

import {
  User_users_organization_price_group,
  User_users_organization_price_group_stat_type,
} from '../hasura/graphQlQueries/types/User'

import {
  Case_cases,
  Case_cases_dicom_server_organization_price_group,
  Case_cases_dicom_server_organization_price_group_stat_type,
} from '../hasura/graphQlQueries/types/Case'

export const codeForStatType = (stat_type?: Prices_prices_stat_type | ConsultationsForOrganization_consultations_stat_type | null) => {
  if (!stat_type?.hours) return 'REG'

  return stat_type.hours === 1 ? 'STAT' : 'PRTY'
}

// TODO: This is a temporary solution until we have a better way to determine the sku
export const skuForConsultation = (consultation: CompletedConsultation) => {
  const isUltrasound = consultation.consultation_type_id === getConsultationTypeForModality(Modality.Ultrasound)?.consultationTypeId
  let sku = isUltrasound ? 'ULTRA' : 'RAD'

  if (!isUltrasound) {
    const medicalImageCount = consultation.case.medical_images_aggregate.aggregate?.count || 0
    if (medicalImageCount > 11) {
      sku += '12+'
    } else if (medicalImageCount > 6) {
      sku += '7-12'
    } else {
      sku += '1-6'
    }
  }

  sku += '/'
  sku += codeForStatType(consultation.stat_type)
  return sku
}

export const getStatPriceAmount = (id: number, _case: Case_cases, modality: Modality): number =>
  (_case.dicom_server?.organization?.price_group || []).find(
    (p) => p.stat_type?.id === id && p.consultation_type_id === getConsultationTypeForModality(modality)?.consultationTypeId
  )?.amount

type StatType =
  | Case_cases_dicom_server_organization_price_group_stat_type
  | User_users_organization_price_group_stat_type
  | Consultations_consultations_stat_type
  | SalesData_organizations_price_group_stat_type
  | null

export const statTypeDescription = (defaultTurnaroundTime: number, statType?: StatType, isExotic?: boolean) =>
  isNumber(statType?.hours)
    ? statType!.hours === 0
      ? 'Immediate STAT'
      : `${statType!.hours}hr STAT`
    : `${isExotic ? 72 : defaultTurnaroundTime}hr STANDARD`

export const statDescription = (statType: StatType) =>
  statType ? (statType.hours === 0 ? 'Immediate STAT' : `${statType.hours}hr STAT`) : undefined

export const getAverageReturnTimesForStatTypes = (
  consultations: OrganizationBilling_consultations[] | EnterpriseBilling_overflow_consults[]
) => {
  const groupedByStatType = groupBy(
    consultations.filter((c) => c.stat_type_id),
    'stat_type_id'
  )

  return sortBy(
    Object.keys(groupedByStatType).map((key) => {
      const statType = groupedByStatType[key][0].stat_type
      const description = statDescription(statType)
      const turnaroundTime = formatDuration(moment.duration(mean(groupedByStatType[key].map((c) => consultationTurnaroundTime(c)))))
      return { description, turnaroundTime, hours: statType?.hours }
    }),
    'hours'
  )
}

export const getSoftwareServiceUnitAmountsForPriceGroup = (priceGroup?: User_users_organization_price_group[]) => {
  if (!priceGroup) return { aiAssessment: 0, caseStorage: 0, supportedFullConsult: 0 }

  const caseStorage =
    (priceGroup.find(
      (s) => s.software_charge_type_id && softwareChargeTypeForId(s.software_charge_type_id) === SoftwareChargeType.CaseStorage
    )?.amount || 0) * 100

  const aiAssessment =
    (priceGroup.find(
      (s) => s.software_charge_type_id && softwareChargeTypeForId(s.software_charge_type_id) === SoftwareChargeType.AiAssessment
    )?.amount || 0) * 100

  const supportedFullConsult =
    (priceGroup.find(
      (s) =>
        s.software_charge_type_id && softwareChargeTypeForId(s.software_charge_type_id) === SoftwareChargeType.SupportedFullConsult
    )?.amount || 0) * 100

  return { aiAssessment, caseStorage, supportedFullConsult }
}

export const thresholdsForAddOnTypes = (thresholds: (number | null)[]) => {
  const result: any = {}
  thresholds.forEach((threshold, idx) => {
    const isLast = idx === thresholds.length - 1
    const nextThreshold = isLast ? null : thresholds[idx + 1]
    result[idx] = `${threshold ? threshold + 1 : 1}${nextThreshold ? `-${nextThreshold}` : '+'} images`
  })
  return result
}

export const getExtraImagePrices = (
  prices: PriceGroups_price_groups_prices[] | Case_cases_dicom_server_organization_price_group[],
  isExotic: boolean,
  modality: Modality
) =>
  sortBy(
    prices.filter(
      (p) =>
        p.consultation_type_id === getConsultationTypeForModality(modality)?.consultationTypeId &&
        p.addon?.display_name === (modality === Modality.Ultrasound ? 'Extra Images/Videos' : 'Extra Images') &&
        p.is_exotic === isExotic
    ),
    (p) => p.addon?.additional_data['threshold'] || p.addon?.additional_data['image_threshold']
  )

export const WEEKEND_STAT_ADDON_ID = CONFIG.IS_DEVELOPMENT ? 11 : 67

export const getStatTypeDescriptions = (prices: PriceGroups_price_groups_prices[], defaultTurnaroundTime: number) =>
  uniq(
    orderBy(prices, [(p) => (p.stat_type ? p.stat_type.hours : 24)], ['desc']).map((p) =>
      getStatTypeDescription(defaultTurnaroundTime, p.stat_type)
    )
  )

export const getStatTypeDescription = (defaultTurnaroundTime: number, statType: Prices_prices_stat_type | null) => {
  if (!statType) return `${defaultTurnaroundTime}HR STANDARD`

  return statType.hours ? `${statType.hours}HR STAT` : statType.display_name
}

export const getStatPrice = (description: string | null, defaultTurnaroundTime: number, prices: PriceGroups_price_groups_prices[]) =>
  prices.find((p) => !description?.endsWith('STANDARD') && getStatTypeDescription(defaultTurnaroundTime, p.stat_type) === description)
