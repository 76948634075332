import gql from 'graphql-tag'

export const fetchDicomServersQuery = () => ({
  query: gql`
    query DicomServers {
      dicom_servers(order_by: { created_at: desc }) {
        aet
        id
        organization_id
        ip_address
        created_at
        updated_at
        ip_address_geocoded
        first_study_patient_name
        first_study_institution_name
        organization {
          id
          enterprise_id
          display_name
          primary_email
        }
      }
    }
  `,
  route: 'dicom_servers',
})

export const deleteDicomServerQuery = (id: number) => ({
  query: gql`
    mutation DeleteDicomServer($id: Int!) {
      delete_dicom_servers_by_pk(id: $id) {
        id
      }
    }
  `,
  route: 'delete_dicom_servers_by_pk',
  variables: { id },
})

export const insertDicomServerQuery = (object: any) => ({
  query: gql`
    mutation InsertDicomServer($object: dicom_servers_insert_input!) {
      insert_dicom_servers_one(object: $object) {
        id
      }
    }
  `,
  route: 'insert_dicom_servers_one',
  variables: { object },
})

export const updateDicomServerQuery = (variables: any) => ({
  query: gql`
    mutation UpdateDicomServer($id: Int!, $aet: String, $ip_address: String!, $organization_id: Int) {
      update_dicom_servers_by_pk(
        pk_columns: { id: $id }
        _set: { aet: $aet, ip_address: $ip_address, organization_id: $organization_id }
      ) {
        id
      }
    }
  `,
  route: 'update_dicom_servers_by_pk',
  variables,
})

export const dicomServerSuggestionsQuery = (created_at: string) => ({
  query: gql`
    query DicomServerSuggestions($created_at: timestamptz!) {
      dicom_servers(where: { organization_id: { _is_null: true }, _and: { created_at: { _gt: $created_at } } }) {
        id
        aet
        ip_address
        first_study_patient_name
      }
    }
  `,
  route: 'dicom_servers',
  variables: { created_at },
})
