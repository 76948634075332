import flattenDeep from 'lodash/flattenDeep'
import { createSlice } from '@reduxjs/toolkit'

import callHasura from '../callHasura'
import { Addons_addons } from '../graphQlQueries/types/Addons'
import { Modality, getConsultationTypeForModality } from '../../lib/modalityHelpers'
import { NotificationId, setNotificationAction } from './notifications'
import { StatTypes_stat_types } from '../graphQlQueries/types/StatTypes'
import { User_users } from '../graphQlQueries/types/User'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { fetchOrganizationsAction, fetchPricesGroupsAction } from './organizations'
import { fetchUserAction } from './users'
import { prices_insert_input } from '../../../types/globalTypes'

import {
  deletePriceGroupQuery,
  deletePricesQuery,
  fetchAddonsQuery,
  fetchStatTypesQuery,
  insertPriceGroupQuery,
  insertPricesQuery,
  updateDefaultPriceGroupQuery,
  updateOrganizationPriceGroupQuery,
  updatePriceAmountQuery,
} from '../graphQlQueries/Pricing'

export interface PricingState {
  addons: Addons_addons[]
  isQuerying: any
  statTypes: StatTypes_stat_types[]
}

const initialState: PricingState = {
  addons: [],
  isQuerying: {},
  statTypes: [],
}

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchStatTypesSuccess: (state, { payload }) => {
      state.statTypes = payload
    },

    fetchAddonsSuccess: (state, { payload }) => {
      state.addons = payload
    },
  },
})

export const { setLoading, networkingFailure, networkingSuccess, fetchStatTypesSuccess, fetchAddonsSuccess } = pricingSlice.actions

export const pricingSelector = (state: any) => state.pricing

export default pricingSlice.reducer

export function fetchStatTypesAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const statTypes: StatTypes_stat_types[] = await callHasura(accessToken, fetchStatTypesQuery())
      dispatch(fetchStatTypesSuccess(statTypes))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchAddonsAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const addons: Addons_addons[] = await callHasura(accessToken, fetchAddonsQuery())
      dispatch(fetchAddonsSuccess(addons))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deletePriceGroupAction(accessToken: string, user: User_users, id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deletePriceGroupQuery(id))
      dispatch(fetchPricesGroupsAction(accessToken, user.organization.enterprise.id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deletePricesAction(accessToken: string, user: User_users, ids: number[]) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deletePricesQuery(ids))
      dispatch(fetchPricesGroupsAction(accessToken, user.organization.enterprise.id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertPricesAction(accessToken: string, user: User_users, prices: prices_insert_input[]) {
  return async (dispatch: any) => {
    const query = insertPricesQuery(prices)
    dispatch(setLoading(query.name))

    try {
      await callHasura(accessToken, query)
      await dispatch(fetchPricesGroupsAction(accessToken, user.organization.enterprise.id))
      dispatch(networkingSuccess(query.name))
    } catch (error) {
      dispatch(networkingFailure(query.name))
    }
  }
}

export function createPriceGroupAction(
  accessToken: string,
  user: User_users,
  modalities: Modality[],
  statTypes: StatTypes_stat_types[]
) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const priceGroup = await callHasura(accessToken, insertPriceGroupQuery({ enterprise_id: user.organization.enterprise.id }))
      if (priceGroup) {
        const prices = modalities.map((modality) => {
          const consultationTypeData = getConsultationTypeForModality(modality)
          return [...statTypes, null].map((statType) => {
            return {
              amount: 0,
              modality_id: consultationTypeData?.modalityId,
              stat_type_id: statType?.id,
              price_group_id: priceGroup.id,
              consultation_type_id: consultationTypeData?.consultationTypeId,
            }
          })
        })
        await callHasura(accessToken, insertPricesQuery(flattenDeep(prices)))
        dispatch(fetchPricesGroupsAction(accessToken, user.organization.enterprise.id))
        dispatch(setNotificationAction(NotificationId.PriceGroupCreated))
      }
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function addModalityToPriceGroupAction(
  accessToken: string,
  user: User_users,
  price_group_id: number,
  modality: Modality,
  statTypes: StatTypes_stat_types[]
) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const consultationTypeData = getConsultationTypeForModality(modality)
      const consultation_type_id = consultationTypeData?.consultationTypeId
      const prices: prices_insert_input[] = [...statTypes, null].map((statType) => {
        return {
          amount: 0,
          modality_id: consultationTypeData?.modalityId,
          stat_type_id: statType?.id,
          price_group_id,
          consultation_type_id,
        }
      })
      if (modality === Modality.Catscan) {
        prices.push(
          ...[
            { addon_id: 1, amount: 0, price_group_id, modality_id: consultationTypeData?.modalityId, consultation_type_id },
            { addon_id: 2, amount: 0, price_group_id, modality_id: consultationTypeData?.modalityId, consultation_type_id },
          ]
        )
      }
      await callHasura(accessToken, insertPricesQuery(prices))
      dispatch(fetchPricesGroupsAction(accessToken, user.organization.enterprise.id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updatePriceAmountAction(accessToken: string, user: User_users, id: number, amount: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updatePriceAmountQuery(id, amount))
      await dispatch(fetchPricesGroupsAction(accessToken, user.organization.enterprise.id))
      dispatch(setNotificationAction(NotificationId.PriceSaved))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateDefaultPriceGroupAction(accessToken: string, user: User_users, default_price_group_id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateDefaultPriceGroupQuery(user.organization.enterprise.id, default_price_group_id))
      dispatch(fetchUserAction(accessToken, user!.id, false))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateOrganizationPriceGroupAction(
  accessToken: string,
  user: User_users,
  organization_id: number,
  price_group_id: number
) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateOrganizationPriceGroupQuery(organization_id, price_group_id))
      await dispatch(fetchOrganizationsAction(accessToken, user.organization.enterprise.id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
