import gql from 'graphql-tag'

import { ENTERPRISE_FIELDS } from './fragments/enterpriseFields'

export const fetchEnterpriseQuery = (id: number) => ({
  query: gql`
    ${ENTERPRISE_FIELDS}
    query Enterprise($id: Int!) {
      enterprises_by_pk(id: $id) {
        ...enterprise_fields
      }
    }
  `,
  route: 'enterprises_by_pk',
  variables: { id },
})

export const fetchEnterprisesQuery = () => ({
  query: gql`
    ${ENTERPRISE_FIELDS}
    query Enterprises {
      enterprises(order_by: { short_name: asc }) {
        ...enterprise_fields
      }
    }
  `,
  route: 'enterprises',
})

export const fetchEnterpriseBillingQuery = (id: number, gt: string, lt: string) => ({
  query: gql`
    query EnterpriseBilling($id: Int!, $gt: timestamptz!, $lt: timestamptz!) {
      cases_stored: cases(
        where: { dicom_server: { organization: { enterprise_id: { _eq: $id } } }, created_at: { _gt: $gt, _lt: $lt } }
      ) {
        dicom_server {
          organization_id
        }
      }

      ai_assessments: cases(
        where: {
          s3_url: { _is_null: false }
          dicom_server: { organization: { enterprise_id: { _eq: $id } } }
          created_at: { _gt: $gt, _lt: $lt }
        }
      ) {
        dicom_server {
          organization_id
        }
      }

      supported_full_consults: consultations(
        where: {
          case: { dicom_server: { organization: { enterprise_id: { _eq: $id } } } }
          completed_at: { _gt: $gt, _lt: $lt }
          completed_by_enterprise_id: { _eq: $id }
        }
      ) {
        stat_type_id
        case {
          dicom_server {
            organization_id
          }
        }
      }

      overflow_consults: consultations(
        where: {
          case: { dicom_server: { organization: { enterprise_id: { _eq: $id } } } }
          completed_at: { _gt: $gt, _lt: $lt }
          completed_by_enterprise_id: { _neq: $id }
        }
        order_by: { completed_at: asc }
      ) {
        id
        created_at
        overflowed_at
        upgraded_at
        completed_at
        overflow_price_amount
        price_amount
        sending_vet_notes
        stat_type_id
        consultation_type {
          display_name
        }
        case {
          patient {
            display_name
            species
          }
          medical_images_aggregate {
            aggregate {
              count
            }
          }
          dicom_server {
            organization_id
            organization {
              display_name
            }
          }
        }
        stat_type {
          hours
        }
        overflow_stat_type {
          hours
        }
      }
    }
  `,
  variables: { id, gt, lt },
})

export const updateOverflowRuleQuery = (id: number, rule: number) => ({
  query: gql`
    mutation UpdateOverflowRule($id: Int!, $rule: Int!) {
      update_overflow_rules_by_pk(pk_columns: { id: $id }, _set: { rule: $rule }) {
        id
      }
    }
  `,
  route: 'update_overflow_rules_by_pk',
  variables: { id, rule },
})

export const updateEnterpriseDefaultTurnaroundHoursQuery = (id: number, default_turnaround_hours: number) => ({
  query: gql`
    mutation UpdateEnterpriseDefaultTurnaroundHours($id: Int!, $default_turnaround_hours: Int!) {
      update_enterprises_by_pk(pk_columns: { id: $id }, _set: { default_turnaround_hours: $default_turnaround_hours }) {
        id
      }
    }
  `,
  route: 'update_enterprises_by_pk',
  variables: { id, default_turnaround_hours },
})
