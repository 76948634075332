import React from 'react'
import { ApolloProvider } from '@apollo/client/react'
import { Auth0Provider } from '@auth0/auth0-react'
import { ErrorBoundary } from 'react-error-boundary'
import { IntercomProvider } from 'react-use-intercom'
import { navigate } from 'gatsby'

import ReduxWrapper from './src/ReduxWrapper'
import CONFIG from './src/config'
import { ErrorFallback } from './src/components/common/ErrorFallback'
import apolloClient from './src/apollo'

const onRedirectCallback = (appState) => navigate(appState?.returnTo || '/')

const errorHandler = (error, _) => {
  // @ts-ignore
  if (window.Sentry) window.Sentry.captureException(error)
}

export const wrapRootElement = (props) => {
  return (
    <IntercomProvider autoBoot={true} appId={CONFIG.INTERCOM_APP_ID}>
      <ApolloProvider client={apolloClient}>
        <Auth0Provider
          audience={CONFIG.AUTH0_AUDIENCE}
          clientId={CONFIG.AUTH0_CLIENT_ID}
          domain={CONFIG.AUTH0_DOMAIN}
          onRedirectCallback={onRedirectCallback}
          redirectUri={window.location.origin}
        >
          <ErrorBoundary onError={errorHandler} FallbackComponent={ErrorFallback}>
            <ReduxWrapper {...props} />
          </ErrorBoundary>
        </Auth0Provider>
      </ApolloProvider>
    </IntercomProvider>
  )
}
