import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import rootReducer from './hasura/slices/index'

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
})

export default function createStore() {
  return configureStore({ reducer: rootReducer, middleware: customizedMiddleware })
}
