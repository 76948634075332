import sum from 'lodash/sum'
import toNumber from 'lodash/toNumber'

import { Consultations_consultations } from '../hasura/graphQlQueries/types/Consultations'
import { Modality, modalityFor } from './modalityHelpers'

export const additionalImagesCount = (notes?: string | null) => {
  // @ts-ignore
  const compareText = (notes || '').split(/\* (?:Compare to|Combine with): (.*)/s)[1] || ''
  return sum(compareText?.match(/(\d+) image/g)?.map((s) => toNumber(s.split(' image')[0])))
}

export const combineCompareCaseIds = (notes?: string | null) => {
  // @ts-ignore
  const compareText = (notes || '').split(/\* (?:Compare to|Combine with): (.*)/s)[1] || ''
  return compareText?.match(/\((\d+)\)/g)?.map((s) => toNumber(s.slice(1, -1))) || []
}

export const notesWithoutCombineCompareCaseIds = (notes?: string | null) => {
  const ids = combineCompareCaseIds(notes)
  return ids.map((id) => ` (${id})`).reduce((acc, sub) => (acc || '').replace(sub, ''), notes)
}

export const isCombineCompareCase = (notes?: string | null) => additionalImagesCount(notes) > 0

export interface DownloadStudyParams {
  modality?: Modality
  isCombineCompare: boolean
  combineCompareCaseIds?: number[]
  patientName?: string
  studyId: string
  sourceIsDicomCluster: boolean
}

type Consultation = Consultations_consultations

export const downloadStudyParamsFor = (consultations: Consultation[]) =>
  consultations
    .map((consultation): DownloadStudyParams | undefined => {
      const studyId = consultation.case.dicom_server_study_id
      // const studyId = consultation.case.dicom_server_viewing_study_id || consultation.case.dicom_server_study_id
      if (!studyId) {
        console.log(`WARNING - no studyId for consultation ${consultation.id}`)
        return
      }

      return {
        studyId,
        modality: modalityFor(consultation.case),
        isCombineCompare: isCombineCompareCase(consultation.sending_vet_notes),
        combineCompareCaseIds: combineCompareCaseIds(consultation.sending_vet_notes).concat(consultation.case_id),
        patientName: consultation.case.patient.display_name,
        sourceIsDicomCluster: consultation.case.dicom_source === 'dicom_cluster',
      }
    })
    .filter((params): params is DownloadStudyParams => !!params)
