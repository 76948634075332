import gql from 'graphql-tag'

export const AI_FEED_CONSULTATION_FIELDS = gql`
  fragment ai_feed_consultation_fields on consultations {
    id
    completed_at
    receiving_vet_notes
    sending_vet_notes
    s3_url
    case {
      created_at
      additional_data
      patient {
        display_name
        species
      }
      medical_images(order_by: { view: { order_index: asc } }) {
        ai_predictions_denormalized
        aws_s3_url
        type
        view {
          display_name
        }
        medical_image_permutations {
          aws_s3_url
          ai_predictions_denormalized
          label
        }
      }
      medical_images_aggregate {
        aggregate {
          count
        }
      }
    }
    predictions {
      display_name
      condition {
        id
        display_name
        ml_name
      }
    }
    receiving_vet {
      id
      display_name
    }
  }
`
