import gql from 'graphql-tag'
import { distribution_emails_insert_input } from '../../../types/globalTypes'

export const deleteDistributionEmailQuery = (id: number) => ({
  query: gql`
    mutation DeleteDistributionEmail($id: Int!) {
      delete_distribution_emails_by_pk(id: $id) {
        id
      }
    }
  `,
  route: 'delete_distribution_emails_by_pk',
  variables: { id },
})

export const insertDistributionEmailQuery = (object: distribution_emails_insert_input) => ({
  query: gql`
    mutation InsertDistributionEmail($object: distribution_emails_insert_input!) {
      insert_distribution_emails_one(object: $object) {
        id
      }
    }
  `,
  route: 'insert_distribution_emails_one',
  variables: { object },
})

export interface UpdateDistributionEmailProps {
  id: number
  send_ai_reports: boolean
  send_human_reports: boolean
  email: string
}

export const updateDistributionEmailQuery = (variables: UpdateDistributionEmailProps) => ({
  query: gql`
    mutation UpdateDistributionEmail($id: Int!, $send_ai_reports: Boolean!, $send_human_reports: Boolean!, $email: String!) {
      update_distribution_emails_by_pk(
        pk_columns: { id: $id }
        _set: { send_ai_reports: $send_ai_reports, send_human_reports: $send_human_reports, email: $email }
      ) {
        id
        email
      }
    }
  `,
  route: 'update_distribution_emails_by_pk',
  variables,
})
