import gql from 'graphql-tag'

import { COMPLETED_CONSULTATION_FIELDS } from './fragments/completedConsultationFields'

export const fetchOrganizationBillingQuery = (gt: string, lt: string, organization_id: number) => ({
  query: gql`
    ${COMPLETED_CONSULTATION_FIELDS}
    query OrganizationBilling($gt: timestamptz!, $lt: timestamptz!, $organization_id: Int) {
      consultations(
        where: {
          case: { dicom_server: { organization_id: { _eq: $organization_id } } }
          _and: { completed_at: { _gt: $gt, _lt: $lt } }
        }
        order_by: { completed_at: asc }
      ) {
        ...completed_consultation_fields
      }

      cases_stored: cases_aggregate(
        where: { dicom_server: { organization_id: { _eq: $organization_id } }, created_at: { _gt: $gt, _lt: $lt } }
      ) {
        aggregate {
          count
        }
      }

      ai_assessments: cases_aggregate(
        where: {
          s3_url: { _is_null: false }
          dicom_server: { organization_id: { _eq: $organization_id } }
          created_at: { _gt: $gt, _lt: $lt }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  variables: { organization_id, gt, lt },
})

export const fetchEnterpriseInvoicesQuery = (gt: string, lt: string, enterprise_id: number) => ({
  query: gql`
    ${COMPLETED_CONSULTATION_FIELDS}
    query EnterpriseInvoices($gt: timestamptz!, $lt: timestamptz!, $enterprise_id: Int) {
      consultations(
        where: {
          case: { dicom_server: { organization: { enterprise_id: { _eq: $enterprise_id } } } }
          completed_at: { _gt: $gt, _lt: $lt }
        }
        order_by: { completed_at: asc }
      ) {
        ...completed_consultation_fields
      }
    }
  `,
  variables: { enterprise_id, gt, lt },
})
