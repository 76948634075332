import gql from 'graphql-tag'

import CONFIG from '../../config'
import { CASE_FIELDS, CASE_FIELDS_FULL } from './fragments/caseFields'
import { CASE_QUESTION_FIELDS } from './fragments/caseQuestionFields'
import { QueryName } from '../queryNames'

export const FETCH_CASES_LIMIT = 25

export const fetchCasesQuery = (
  created_at: string,
  enterprise_id?: number,
  organization_id?: number,
  patientId?: number,
  cursor: number = 100000000,
  name = QueryName.FetchCases
) => {
  if (organization_id) {
    if (organization_id === CONFIG.DEMO_ORGANIZATION_ID) {
      return {
        query: gql`
          ${CASE_FIELDS}
          query CasesAfterCursor2($ids: [Int!]!, $organization_id: Int!) {
            cases(where: { id: { _in: $ids } }, order_by: { id: desc }) {
              ...case_fields
            }
            cases_aggregate(where: { dicom_server: { organization_id: { _eq: $organization_id } } }) {
              aggregate {
                count
              }
            }
          }
        `,
        variables: { ids: CONFIG.DEMO_ORGANIZATION_CASE_IDS, organization_id },
        name,
      }
    } else if (patientId) {
      return {
        query: gql`
          ${CASE_FIELDS}
          query CasesForPatient($limit: Int!, $organization_id: Int!, $patientId: Int!) {
            cases(
              where: { dicom_server: { organization_id: { _eq: $organization_id } }, _and: { patient_id: { _eq: $patientId } } }
              order_by: { id: desc }
              limit: $limit
            ) {
              ...case_fields
            }
            cases_aggregate(where: { dicom_server: { organization_id: { _eq: $organization_id } } }) {
              aggregate {
                count
              }
            }
          }
        `,
        variables: { limit: FETCH_CASES_LIMIT, organization_id, patientId },
        name,
      }
    } else {
      return {
        query: gql`
          ${CASE_FIELDS}
          query CasesAfterCursor($limit: Int!, $organization_id: Int!, $cursor: Int, $created_at: timestamptz!) {
            cases(
              where: {
                dicom_server: { organization_id: { _eq: $organization_id } }
                _and: { id: { _lt: $cursor }, _and: { created_at: { _gte: $created_at } } }
              }
              order_by: { id: desc }
              limit: $limit
            ) {
              ...case_fields
            }
            cases_aggregate(
              where: { dicom_server: { organization_id: { _eq: $organization_id } }, _and: { created_at: { _gte: $created_at } } }
            ) {
              aggregate {
                count
              }
            }
          }
        `,
        variables: { limit: FETCH_CASES_LIMIT, organization_id, cursor, created_at },
        name,
      }
    }
  } else {
    return {
      query: gql`
        ${CASE_FIELDS}
        query Cases($limit: Int!, $cursor: Int, $created_at: timestamptz!, $enterprise_id: Int) {
          cases(
            where: {
              dicom_server: { organization: { enterprise_id: { _eq: $enterprise_id } } }
              id: { _lt: $cursor }
              _and: { created_at: { _gte: $created_at } }
            }
            order_by: { id: desc }
            limit: $limit
          ) {
            ...case_fields
          }
          cases_aggregate(
            where: { created_at: { _gte: $created_at }, dicom_server: { organization: { enterprise_id: { _eq: $enterprise_id } } } }
          ) {
            aggregate {
              count
            }
          }
        }
      `,
      variables: { limit: FETCH_CASES_LIMIT, enterprise_id, cursor, created_at },
      name,
    }
  }
}

export const fetchCaseQuery = (id: number) => ({
  query: gql`
    ${CASE_FIELDS_FULL}
    query Case($id: Int!) {
      cases(where: { id: { _eq: $id } }) {
        ...case_fields_full
      }
    }
  `,
  route: 'cases',
  variables: { id },
})

export const fetchCasesInIdsQuery = (ids: number[]) => ({
  query: gql`
    query CasesInIds($ids: [Int!]!) {
      cases(where: { id: { _in: $ids } }) {
        dicom_server_study_id
      }
    }
  `,
  route: 'cases',
  variables: { ids },
})

export const fetchCaseQuestionsQuery = (id: string) => ({
  query: gql`
    ${CASE_QUESTION_FIELDS}
    query CaseQuestions($id: String!) {
      case_questions(where: { _or: [{ vet_id: { _is_null: true } }, { vet_id: { _eq: $id } }], completed_at: { _is_null: true } }) {
        ...case_question_fields
      }
    }
  `,
  route: 'case_questions',
  variables: { id },
})

export const fetchCaseQuestionQuery = (id: number) => ({
  query: gql`
    ${CASE_QUESTION_FIELDS}
    query CaseQuestion($id: Int!) {
      case_questions_by_pk(id: $id) {
        ...case_question_fields
      }
    }
  `,
  route: 'case_questions_by_pk',
  variables: { id },
})

export const updateCaseQuestionQuery = (id: number, case_responses_denormalized: any, completed: boolean) => ({
  query: gql`
    ${CASE_QUESTION_FIELDS}
    mutation UpdateCaseQuestion($id: Int!, $case_responses_denormalized: jsonb!, $completed_at: timestamptz) {
      update_case_questions_by_pk(
        pk_columns: { id: $id }
        _set: { case_responses_denormalized: $case_responses_denormalized, completed_at: $completed_at }
      ) {
        ...case_question_fields
      }
    }
  `,
  route: 'update_case_questions_by_pk',
  variables: { id, case_responses_denormalized, completed_at: completed ? 'now()' : null },
})

export const claimCaseQuestionQuery = (id: number, vet_id: string) => ({
  query: gql`
    ${CASE_QUESTION_FIELDS}
    mutation ClaimCaseQuestion($id: Int!, $vet_id: String!) {
      update_case_questions_by_pk(pk_columns: { id: $id }, _set: { vet_id: $vet_id }) {
        ...case_question_fields
      }
    }
  `,
  route: 'update_case_questions_by_pk',
  variables: { id, vet_id },
})
