import toNumber from 'lodash/toNumber'

const ENVIRONMENT = process.env.GATSBY_ENVIRONMENT || 'development'
const IS_PRODUCTION = ENVIRONMENT === 'production'
const IS_DEVELOPMENT = ENVIRONMENT === 'development'

const HASURA_GRAPHQL_ADMIN_SECRET = IS_DEVELOPMENT
  ? process.env.GATSBY_HASURA_GRAPHQL_ADMIN_SECRET_DEVELOPMENT
  : process.env.GATSBY_HASURA_GRAPHQL_ADMIN_SECRET

const HASURA_API_URL = IS_DEVELOPMENT ? process.env.GATSBY_HASURA_API_URL_DEVELOPMENT : process.env.GATSBY_HASURA_API_URL

export default {
  ANDY_USER_ID: 'google-oauth2|101828104566753942299',
  APP_START_DATE: '2021-04-01',
  AUDIO_URL: 'https://radimal-sounds.s3.us-east-2.amazonaws.com/',
  AUTH0_AUDIENCE: process.env.GATSBY_AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID: process.env.GATSBY_AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: process.env.GATSBY_AUTH0_DOMAIN,
  CASE_QUESTION_PER_CASE_PAY_AMOUNT: 5,
  CHAT_GPT_API_KEY: process.env.GATSBY_CHAT_GPT_API_KEY,
  DEMO_ORGANIZATION_CASE_IDS: process.env.GATSBY_DEMO_ORGANIZATION_CASE_IDS?.split(',').map(toNumber) || [],
  DEMO_ORGANIZATION_ID: 2938,
  DEMO_ORGANIZATION_USER_ID: 'auth0|60527060adc554006f947754',
  DEVELOPMENT_USER_IDS: ['1', '5', 'auth0|66918caf58726d2d9f5cce03', 'auth0|66918cd0bdd0e1f0f6a06602'],
  ER_MODE_CONDITIONS: ['GDV', 'Pneumothorax', 'Pleural Effusion', 'SI mechanical obstruction', 'Congestive heart failure'],
  ENVIRONMENT,
  ERROR_MESSAGE: process.env.GATSBY_ERROR_MESSAGE,
  GRAMMARLY_CLIENT_ID: process.env.GATSBY_GRAMMARLY_CLIENT_ID,
  HASURA_API_URL: HASURA_API_URL || '',
  HASURA_GRAPHQL_ADMIN_SECRET,
  HEADER_HEIGHT: 85,
  HEALTHY_CONDITION_NAME: 'Healthy',
  HOLIDAY_BONUS: 5,
  INTERCOM_APP_ID: 'j6btrtj7',
  IS_DEVELOPMENT,
  IS_PRODUCTION,
  LONG_QUERY_SECONDS_THRESHOLD: 7,
  MOBILE_STYLE_PX_THRESHOLD: 850,
  PLATFORM_URL: process.env.GATSBY_PLATFORM_URL,
  POST_CONSULT_TAGGING_USER_IDS: 'google-oauth2|101828104566753942299,auth0|60cd03cb431b34006981527c,1',
  RADIMAL_API_URL: process.env.GATSBY_RADIMAL_API_URL,
  RADIMAL_ENTERPRISE_ID: 1,
  REFERRAL_FEATURE_USER_IDS: process.env.GATSBY_REFERRAL_FEATURE_USER_IDS || '',
  SIDE_MENU_WIDTH: 250,
  STAT_LIGHT_ON_BONUS: parseInt(process.env.GATSBY_STAT_LIGHT_ON_BONUS || '') || 15,
  STAT_LIGHT_ON_BONUS_NON_STAT: parseInt(process.env.GATSBY_STAT_LIGHT_ON_BONUS_NON_STAT || '') || 13,
  STAT_OFF_HOUR_BONUS: 10,
  STRIPE_AI_FULL_CONSULT_PLAN_ID: 'prod_Jjj2uVRO1Zi71t',
  STRIPE_FULL_CONSULT_PLAN_ID: 'prod_JeWmHO3csKXy0C',
  STRIPE_KEY: process.env.GATSBY_STRIPE_KEY || '',
  TEST_ORGANIZATION_ID: 45,
  VERBOSE: process.env.GATSBY_VERBOSE === 'true',
  VIEWER_URL: process.env.GATSBY_VIEWER_URL,
}
