import moment from 'moment'

import { Option } from './helpers'
import { OrganizationBilling_consultations } from '../hasura/graphQlQueries/types/OrganizationBilling'
import { EnterpriseBilling_overflow_consults } from '../hasura/graphQlQueries/types/EnterpriseBilling'

// @ts-ignore
const isValidDate = (d) => d instanceof Date && !isNaN(d)

export const parseDate = (dateStr: string) => {
  const date = new Date(dateStr)
  return dateStr && isValidDate(date) ? moment.utc(dateStr).format('MMM D, YYYY') : ''
}

export const consultationTurnaroundTime = (consultation: OrganizationBilling_consultations | EnterpriseBilling_overflow_consults) => {
  const end = moment(consultation.completed_at)
  const start = moment(consultation.upgraded_at || consultation.created_at)
  return moment.duration(end.diff(start))
}

export const formatDuration = (duration: moment.Duration) => {
  const hours = Math.floor(duration.asHours())
  const minutes = Math.floor(duration.asMinutes()) % 60
  return hours === 0 ? `${minutes}min` : `${hours}hr ${minutes}min`
}

export const consultationIsLate = (consultation: OrganizationBilling_consultations | EnterpriseBilling_overflow_consults) =>
  Math.ceil(consultationTurnaroundTime(consultation).asHours()) > (consultation.stat_type?.hours || 48)

export const timeDiffHoursAndMinutes = (startStr: string, endStr: string) => {
  const end = moment(endStr)
  const start = moment(startStr)
  return formatDuration(moment.duration(end.diff(start)))
}

export interface MonthOption extends Option {}

// @ts-ignore
export const dateRange = (startDate: string, endDate: string): string[] => {
  var start = startDate.split('/')
  var end = endDate.split('/')
  var startYear = parseInt(start[2])
  var endYear = parseInt(end[2])
  var dates = []

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[0]) - 1
    var startMon = i === startYear ? parseInt(start[0]) - 1 : 0
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1
      var displayMonth = month < 10 ? '0' + month : month
      dates.push([displayMonth, '01', i].join('/'))
    }
  }
  return dates
}

export const getMonthsRange = (startDate: string, endDate: string): MonthOption[] => {
  const months = dateRange(startDate, endDate)

  return months.map((month, idx) => ({
    value: month,
    label: `${month}${idx === months.length - 1 ? ' (current month)' : ''}${idx === months.length - 2 ? ' (last month)' : ''}`,
  }))
}

export const nextMonth = (month: MonthOption): string => {
  const start = new Date(month.value!)
  const end = new Date(start.getTime())
  end.setMonth(end.getMonth() + 1)
  return end.toLocaleDateString('en-US')
}

export const getTimeSince = (date: Date) => {
  // @ts-ignore
  var seconds = Math.floor((new Date() - date) / 1000)

  var interval = seconds / 31536000

  interval = seconds / 3600
  if (interval > 1) {
    return { hours: Math.floor(interval), minutes: Math.floor((seconds / 60) % 60) }
  }

  interval = seconds / 60
  return { minutes: Math.floor(interval), hours: 0 }
}

export const isWeekendEst = () => {
  const momentEst = moment(new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })))
  return [0, 6].includes(momentEst.day())
}
