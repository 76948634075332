import gql from 'graphql-tag'

export const TRAINING_ITERATION_FIELDS = gql`
  fragment training_iterations_fields on training_iterations {
    id
    completed_at
    created_at
    description
    exclude_vet_ids_denormalized
    medical_image_ids_denormalized
    pay_amount
    species
    type
    feedback
    vet_id
    vet {
      display_name
    }
    condition {
      id
      active_learning_cat_priority
      active_learning_dog_priority
      display_name
      grade_type
      key_values_json
      ml_config
      training_iteration_prompt
    }
    predictions_normalizeds {
      vet_id
      medical_images_id
      grade
      issue
      key_values_json
      display_name
    }
  }
`
