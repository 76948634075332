import * as Yup from 'yup'

import { ALL_SPECIES } from '../../lib/helpers'
import { Modality } from '../../lib/modalityHelpers'

export type OtherCase = [string, number]

export const GENDER_OPTIONS = [
  { key: 'Male', value: 'male' },
  { key: 'Female', value: 'female' },
]

export const NEUTERED = 'neutered'
export const INTACT = 'intact'

export const NEUTERED_OPTIONS = [
  { key: 'Neutered', value: NEUTERED },
  { key: 'Intact', value: INTACT },
]

export enum CatscanRegions {
  OneRegion = '1 region',
  TwoRegions = '2 region',
  WholeBody = 'Whole Body',
}

export const CATSCAN_REGION_OPTIONS = Object.values(CatscanRegions).map((a) => ({ key: a, value: a }))

export const requestConsultValidationSchema = (maxImages: number, modality: Modality) =>
  Yup.object()
    .shape({
      display_name: Yup.string().max(100, 'Must be under 100 characters.').required('Name cannot be blank.'),
      breed: Yup.string().max(100, 'Breed must be under 100 characters.').required('Breed cannot be blank.'),
      gender: Yup.mixed()
        .oneOf(GENDER_OPTIONS.map((a) => a.value))
        .required('Gender cannot be blank.'),
      species: Yup.mixed().oneOf(ALL_SPECIES).required('Species cannot be blank.'),
      notes: Yup.string()
        .min(3, 'Report notes cannot be blank.')
        .max(2000, 'Notes must be under 2,000 characters.')
        .required('Report notes cannot be blank.'),
      neutered: Yup.mixed()
        .oneOf(NEUTERED_OPTIONS.map((a) => a.value))
        .required('Neutered/intact cannot be blank.'),
      private_notes: Yup.string(),
      imagesCount: Yup.number().max(maxImages, `Total number of images must be between 1 and ${maxImages}.`),
      weight: modality === Modality.Xray ? Yup.string().nullable() : Yup.string().nullable().required('Weight cannot be blank.'),
      regions:
        modality === Modality.Catscan ? Yup.string().nullable().required('Region(s) cannot be blank.') : Yup.string().nullable(),
    })
    .concat(
      Yup.object().shape(
        {
          age: Yup.string().when('birthdate', {
            is: (birthdate: any) => !birthdate,
            then: Yup.string().required('Birthdate or est. age required'),
          }),
          birthdate: Yup.string().when('age', {
            is: (age: any) => !age,
            then: Yup.string().required('Birthdate or est. age required'),
          }),
        },
        // @ts-ignore
        ['age', 'birthdate']
      )
    )
