import { combineReducers } from 'redux'

import billingReducer from './billing'
import casesReducer from './cases'
import conditionsReducer from './conditions'
import consultationTypesReducer from './consultationTypes'
import consultationsReducer from './consultations'
import dicomServersReducer from './dicom-servers'
import distributionEmailsReducer from './distribution-emails'
import enterprisesReducer from './enterprises'
import machineLearningReducer from './machine-learning'
import medicalImagesReducer from './medical-images'
import notificationsReducer from './notifications'
import organizationsReducer from './organizations'
import patientsReducer from './patients'
import predictionsReducer from './predictions'
import pricingReducer from './pricing'
import specialistPaymentsReducer from './specialistPayments'
import usersReducer from './users'

const rootReducer = combineReducers({
  billing: billingReducer,
  cases: casesReducer,
  conditions: conditionsReducer,
  consultationTypes: consultationTypesReducer,
  consultations: consultationsReducer,
  dicomServers: dicomServersReducer,
  distributionEmails: distributionEmailsReducer,
  enterprises: enterprisesReducer,
  machineLearning: machineLearningReducer,
  medicalImages: medicalImagesReducer,
  notifications: notificationsReducer,
  organizations: organizationsReducer,
  patients: patientsReducer,
  predictions: predictionsReducer,
  pricing: pricingReducer,
  specialistPayments: specialistPaymentsReducer,
  users: usersReducer,
})

export default rootReducer
