export const defaultSetLoading = (state: any, { payload }: { payload: string | undefined }) => {
  state.loading = true
  if (payload) state.isQuerying[payload] = true
}

export const defaultNetworkingFailure = (state: any, { payload }: { payload: string | undefined }) => {
  state.loading = false
  if (payload && state.isQuerying) state.isQuerying[payload] = false
  state.hasErrors = true
}

export const defaultNetworkingSuccess = (state: any, { payload }: { payload: string | undefined }) => {
  state.loading = false
  if (payload && state.isQuerying) state.isQuerying[payload] = false
  state.hasErrors = false
}
