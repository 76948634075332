import { createSlice } from '@reduxjs/toolkit'

import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import callFlask from '../callFlask'

export interface MachineLearningState {
  isQuerying: any
  mlServerStatus?: string
}

const initialState: MachineLearningState = {
  isQuerying: {},
}

const machineLearningSlice = createSlice({
  name: 'machineLearning',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    setMlServerStatus: (state, { payload }) => {
      state.mlServerStatus = payload
    },
  },
})

export const { networkingFailure, networkingSuccess, setLoading, setMlServerStatus } = machineLearningSlice.actions

export const machineLearningSelector = (state: any) => state.machineLearning

export default machineLearningSlice.reducer

export function fetchMLServerStatusAction() {
  return async (dispatch: any) => {
    const result = await callFlask('/ml-training-server/status', 'POST')
    if (result.status) {
      dispatch(setMlServerStatus(result.status))
    } else {
      dispatch(networkingFailure())
    }
  }
}

export function updateMLServerStatusAction(on: boolean) {
  return async (dispatch: any) => {
    await callFlask(`/ml-training-server/${on ? 'stop' : 'start'}`, 'POST')
    dispatch(fetchMLServerStatusAction())
  }
}
