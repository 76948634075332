import gql from 'graphql-tag'

import { PatientParams } from '../../components/request-consult/helpers'
import { pims_patients_insert_input } from '../../../types/globalTypes'

export const fetchPatientsForOrganizationQuery = (organization_id: number, input: string, limit: number = 1000) => {
  if (input) {
    return {
      query: gql`
        query PatientsForInput($organization_id: Int!, $limit: Int!, $input: String!) {
          patients(
            where: {
              cases: { dicom_server: { organization_id: { _eq: $organization_id } } }
              _and: { display_name: { _ilike: $input } }
            }
            order_by: { display_name: asc }
            limit: $limit
          ) {
            id
            display_name
            species
            dicom_server_patient_id
            cases(order_by: { id: desc }, limit: 1) {
              created_at
            }
          }
        }
      `,
      route: 'patients',
      variables: { organization_id, input: `%${input}%`, limit },
    }
  } else {
    return {
      query: gql`
        query Patients($organization_id: Int!, $limit: Int!) {
          patients(
            where: { cases: { dicom_server: { organization_id: { _eq: $organization_id } } } }
            order_by: { display_name: asc }
            limit: $limit
          ) {
            id
            display_name
            species
            dicom_server_patient_id
            cases(order_by: { id: desc }, limit: 1) {
              created_at
            }
          }
        }
      `,
      route: 'patients',
      variables: { organization_id, limit },
    }
  }
}

export const updatePatientSpeciesQuery = (id: number, species: String) => ({
  query: gql`
    mutation UpdatePatientSpecies($id: Int!, $species: String!) {
      update_patients_by_pk(pk_columns: { id: $id }, _set: { species: $species }) {
        id
      }
    }
  `,
  route: 'update_patients_by_pk',
  variables: { id, species },
})

export const updateCasePatientIdQuery = (id: number, patient_id: number) => ({
  query: gql`
    mutation UpdateCasePatientId($id: Int!, $patient_id: Int!) {
      update_cases_by_pk(pk_columns: { id: $id }, _set: { patient_id: $patient_id }) {
        id
      }
    }
  `,
  route: 'update_cases_by_pk',
  variables: { id, patient_id },
})

export const insertPimsPatientQuery = (object: pims_patients_insert_input) => ({
  query: gql`
    mutation InsertPimsPatient($object: pims_patients_insert_input!) {
      insert_pims_patients_one(object: $object) {
        id
      }
    }
  `,
  route: 'insert_pims_patients_one',
  variables: { object },
})

export const updatePatientQuery = (params: PatientParams) => ({
  query: gql`
    mutation UpdatePatient(
      $age: String
      $birthdate: date
      $breed: String
      $display_name: String!
      $gender: String!
      $id: Int!
      $neutered: Boolean!
      $species: String!
      $weight: String
    ) {
      update_patients_by_pk(
        pk_columns: { id: $id }
        _set: {
          age: $age
          birthdate: $birthdate
          breed: $breed
          display_name: $display_name
          gender: $gender
          neutered: $neutered
          species: $species
          weight: $weight
        }
      ) {
        id
      }
    }
  `,
  route: 'update_patients_by_pk',
  variables: {
    age: params.age,
    birthdate: params.birthdate,
    breed: params.breed,
    display_name: params.display_name,
    gender: params.gender,
    id: params.id,
    neutered: params.neutered,
    species: params.species,
    weight: params.weight,
  },
})
