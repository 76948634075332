import { createSlice } from '@reduxjs/toolkit'

import callHasura from '../callHasura'
import { ConditionCategories_condition_categories } from '../graphQlQueries/types/ConditionCategories'
import { ConditionPredictionCounts_conditions } from '../graphQlQueries/types/ConditionPredictionCounts'
import { NotificationId, setNotificationAction } from './notifications'
import { case_questions_insert_input, conditions_insert_input } from '../../../types/globalTypes'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { fetchConditionsAction } from './consultations'

import {
  fetchConditionCategoriesQuery,
  fetchConditionPredictionCountsQuery,
  insertCaseQuestionQuery,
  insertConditionQuery,
  updateConditionActiveLearningCatPriorityQuery,
  updateConditionActiveLearningDogPriorityQuery,
  updateConditionAiNotesQuery,
  updateConditionSymptomsQuery,
  updateConditionTrainAndDeployCatQuery,
  updateConditionTrainAndDeployDogQuery,
} from '../graphQlQueries/Conditions'

export interface ConditionsState {
  conditionPredictionCounts: ConditionPredictionCounts_conditions[]
  isQuerying: any
  conditionCategories: ConditionCategories_condition_categories[]
}

const initialState: ConditionsState = { conditionPredictionCounts: [], isQuerying: {}, conditionCategories: [] }

const conditionsSlice = createSlice({
  name: 'conditions',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchConditionPredictionCountsSuccess: (state, { payload }: { payload: ConditionPredictionCounts_conditions[] }) => {
      state.conditionPredictionCounts = payload
    },

    fetchConditionCategoriesSuccess: (state, { payload }: { payload: ConditionCategories_condition_categories[] }) => {
      state.conditionCategories = payload
    },
  },
})

export const {
  networkingFailure,
  networkingSuccess,
  setLoading,
  fetchConditionPredictionCountsSuccess,
  fetchConditionCategoriesSuccess,
} = conditionsSlice.actions

export const conditionsSelector = (state: any) => state.conditions

export default conditionsSlice.reducer

export function fetchConditionPredictionCountsAction(accessToken: string) {
  return async (dispatch: any) => {
    const query = fetchConditionPredictionCountsQuery()
    dispatch(setLoading(query.name))

    try {
      const result: ConditionPredictionCounts_conditions[] = await callHasura(accessToken, query)
      dispatch(fetchConditionPredictionCountsSuccess(result))
      dispatch(networkingSuccess(query!.name))
    } catch (error) {
      dispatch(networkingFailure(query!.name))
    }
  }
}

export function insertConditionAction(accessToken: string, object: conditions_insert_input) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, insertConditionQuery(object))
      dispatch(fetchConditionsAction(accessToken))
      dispatch(setNotificationAction(NotificationId.ConditionCreated))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(setNotificationAction(NotificationId.InvalidParameters))
      dispatch(networkingFailure())
    }
  }
}

export function insertCaseQuestionAction(accessToken: string, object: case_questions_insert_input) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, insertCaseQuestionQuery(object))
      dispatch(setNotificationAction(NotificationId.CaseQuestionCreated))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchConditionCategoriesAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const conditionCategories = await callHasura(accessToken, fetchConditionCategoriesQuery())
      dispatch(fetchConditionCategoriesSuccess(conditionCategories))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateConditionSymptomsAction(accessToken: string, id: number, symptoms_description_short: string) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateConditionSymptomsQuery(id, symptoms_description_short))
      dispatch(fetchConditionsAction(accessToken))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateConditionAiNotesAction(accessToken: string, id: number, ai_notes: string) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateConditionAiNotesQuery(id, ai_notes))
      dispatch(fetchConditionsAction(accessToken))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateConditionActiveLearningPriorityAction(accessToken: string, id: number, priority: number, species: string) {
  return async (dispatch: any) => {
    try {
      const fn = species === 'dog' ? updateConditionActiveLearningDogPriorityQuery : updateConditionActiveLearningCatPriorityQuery
      await callHasura(accessToken, fn(id, priority))
      dispatch(fetchConditionPredictionCountsAction(accessToken))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateTrianAndDeployAction(accessToken: string, id: number, trainAndDeploy: boolean, species: string) {
  return async (dispatch: any) => {
    try {
      const fn = species === 'dog' ? updateConditionTrainAndDeployDogQuery : updateConditionTrainAndDeployCatQuery
      await callHasura(accessToken, fn(id, trainAndDeploy))
      dispatch(fetchConditionPredictionCountsAction(accessToken))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
