import gql from 'graphql-tag'
import moment from 'moment'

export const fetchUserQuery = (id: any) => ({
  query: gql`
    query User($id: String!, $beginning_of_day: timestamptz!, $beginning_of_week: timestamptz!, $beginning_of_month: timestamptz!) {
      users(where: { id: { _eq: $id } }) {
        id
        audio_alerts_on
        dark_mode_on
        display_name
        email
        created_at

        related_accounts {
          auth0_profile {
            picture
            app_metadata {
              hubSpotContactId
            }
          }
          stripe_profile {
            id
            email
            subscriptions {
              total_count
              data {
                plan {
                  product
                }
              }
            }
          }
        }

        training_iterations_daily: training_iterations_aggregate(where: { completed_at: { _gt: $beginning_of_day } }) {
          aggregate {
            sum {
              pay_amount
            }
          }
        }

        training_iterations_weekly: training_iterations_aggregate(where: { completed_at: { _gt: $beginning_of_week } }) {
          aggregate {
            sum {
              pay_amount
            }
          }
        }

        training_iterations_monthly: training_iterations_aggregate(where: { completed_at: { _gt: $beginning_of_month } }) {
          aggregate {
            sum {
              pay_amount
            }
          }
        }

        daily_total: consultations_aggregate(where: { status: { _eq: "completed" }, completed_at: { _gt: $beginning_of_day } }) {
          aggregate {
            sum {
              receiving_vet_pay_amount
            }
          }
        }

        weekly_total: consultations_aggregate(where: { status: { _eq: "completed" }, completed_at: { _gt: $beginning_of_week } }) {
          aggregate {
            sum {
              receiving_vet_pay_amount
            }
          }
        }

        monthly_total: consultations_aggregate(where: { status: { _eq: "completed" }, completed_at: { _gt: $beginning_of_month } }) {
          aggregate {
            sum {
              receiving_vet_pay_amount
            }
          }
        }

        related_accounts {
          auth0_profile {
            picture
            user_metadata {
              image
            }
          }
        }

        user_roles {
          id
          created_at
          updated_at
          user_role_consultation_types {
            id
            consultation_type {
              id
              display_name
            }
          }
          enterprise {
            id
            logo_aws_s3_url
            short_name
          }
          role
        }

        organization {
          id
          created_at
          display_name
          display_pricing
          enable_billing_flow
          number_of_vets
          pims_email
          price_group_id
          price_per_consultation_usd
          primary_email
          primary_phone_number
          ai_mode {
            id
            display_name
          }
          enterprise {
            id
            ai_enabled
            default_overflow
            default_turnaround_hours
            enterprise_admin_has_overflow_control
            enterprise_admin_turnaround_times_on_invoices
            default_price_group_id
            in_evaluation
            logo_aws_s3_url
            short_name
            vet_has_overflow_control
            specialist_payment_groups {
              id
            }
            overflow_rules {
              id
              rule
            }
          }
          price_group {
            addon_type
            amount
            consultation_type_id
            is_exotic
            software_charge_type_id
            specialist_amount
            modality {
              id
              display_name
            }
            addon {
              id
              display_name
              additional_data
            }
            stat_type {
              id
              hours
            }
          }
          pims_organizations {
            ezyvet_email
            vetspire_api_url
          }
          dicom_servers {
            aet
            id
            ip_address
            cases_aggregate {
              aggregate {
                count
              }
            }
          }
          distribution_emails(order_by: { display_name: asc_nulls_first }) {
            id
            email
            display_name
            send_ai_reports
            send_human_reports
          }
        }
      }
    }
  `,
  route: 'users',
  variables: {
    id: String(id),
    beginning_of_day: moment().startOf('day'),
    beginning_of_week: moment().startOf('week'),
    beginning_of_month: moment().startOf('month'),
  },
})

export const updateUserAudioAlertsOnQuery = (id: string, audio_alerts_on: boolean) => ({
  query: gql`
    mutation UpdateUserAudioAlerts($id: String!, $audio_alerts_on: Boolean!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { audio_alerts_on: $audio_alerts_on }) {
        id
      }
    }
  `,
  route: 'update_users_by_pk',
  variables: { id, audio_alerts_on },
})

export const updateUserDarkModeOnQuery = (id: string, dark_mode_on: boolean) => ({
  query: gql`
    mutation UpdateUserDarkModeOn($id: String!, $dark_mode_on: Boolean!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { dark_mode_on: $dark_mode_on }) {
        id
      }
    }
  `,
  route: 'update_users_by_pk',
  variables: { id, dark_mode_on },
})

export const updateUserDisplayNameQuery = (id: string, display_name: string) => ({
  query: gql`
    mutation UpdateUserDisplayName($id: String!, $display_name: String!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { display_name: $display_name }) {
        id
      }
    }
  `,
  route: 'update_users_by_pk',
  variables: { id, display_name },
})

export const fetchVetsQuery = (enterpriseId: number) => ({
  query: gql`
    query Vets($enterpriseId: Int!) {
      users(
        where: {
          display_name: { _similar: "%(DACVR|VMD|DVM)%" }
          _or: [
            { organization: { enterprise_id: { _eq: $enterpriseId } } }
            { user_roles: { enterprise_id: { _eq: $enterpriseId }, role: { _eq: "vet" } } }
          ]
        }
        order_by: { display_name: asc }
      ) {
        id
        display_name
      }
    }
  `,
  route: 'users',
  variables: { enterpriseId },
})

export const fetchVetsExpandedQuery = (enterprise_id: number) => ({
  query: gql`
    query VetsExpanded(
      $beginning_of_day: timestamptz!
      $beginning_of_month: timestamptz!
      $last_30_days: timestamptz!
      $enterprise_id: Int!
    ) {
      users(
        where: {
          display_name: { _similar: "%(DACVR|VMD|DVM)%" }
          _or: [
            { organization: { enterprise_id: { _eq: $enterprise_id } } }
            { user_roles: { enterprise_id: { _eq: $enterprise_id }, role: { _eq: "vet" } } }
          ]
        }
      ) {
        id
        active
        created_at
        updated_at
        role
        display_name
        email

        related_accounts {
          auth0_profile {
            picture
            user_metadata {
              image
            }
          }
        }

        user_roles(where: { role: { _eq: "vet" } }) {
          enterprise {
            short_name
          }
        }

        daily_total_labels: predictions_normalizeds_aggregate(
          where: {
            type: { _in: ["specialist-medical_image", "specialist-medical_image-binary_tagger"] }
            created_at: { _gt: $beginning_of_day }
          }
        ) {
          aggregate {
            count
          }
        }

        monthly_total_labels: predictions_normalizeds_aggregate(
          where: {
            type: { _in: ["specialist-medical_image", "specialist-medical_image-binary_tagger"] }
            created_at: { _gt: $beginning_of_month }
          }
        ) {
          aggregate {
            count
          }
        }

        daily_total_consults: consultations_aggregate(
          where: { status: { _eq: "completed" }, completed_at: { _gt: $beginning_of_day } }
        ) {
          aggregate {
            count
          }
        }

        monthly_total_consults: consultations_aggregate(
          where: { status: { _eq: "completed" }, completed_at: { _gt: $beginning_of_month } }
        ) {
          aggregate {
            count
          }
        }

        last_30_days_total_consults: consultations_aggregate(
          where: { status: { _eq: "completed" }, completed_at: { _gt: $last_30_days } }
        ) {
          aggregate {
            count
          }
        }

        incomplete_training_iterations: training_iterations(where: { completed_at: { _is_null: true } }) {
          condition {
            display_name
          }
        }
      }
    }
  `,
  route: 'users',
  variables: {
    beginning_of_day: moment().startOf('day'),
    beginning_of_month: moment().startOf('month'),
    last_30_days: moment().subtract(30, 'day'),
    enterprise_id,
  },
})

export const fetchUserInfoQuery = (organization_id: number) => ({
  query: gql`
    query UserInfo($organization_id: Int, $beginning_of_month: timestamptz!) {
      cases_aggregate(
        distinct_on: patient_id
        where: { dicom_server: { organization_id: { _eq: $organization_id } }, created_at: { _gt: $beginning_of_month } }
      ) {
        aggregate {
          count
        }
      }

      requested_consultations_aggregate: consultations_aggregate(
        where: {
          status: { _eq: "requested" }
          case: { dicom_server: { organization_id: { _eq: $organization_id } } }
          created_at: { _gt: $beginning_of_month }
        }
      ) {
        aggregate {
          count
        }
      }

      completed_consultations_aggregate: consultations_aggregate(
        where: {
          status: { _eq: "completed" }
          case: { dicom_server: { organization_id: { _eq: $organization_id } } }
          created_at: { _gt: $beginning_of_month }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  variables: { organization_id, beginning_of_month: moment().startOf('month') },
})

export const updateVetActiveQuery = (id: string, active: boolean) => ({
  query: gql`
    mutation UpdateVetActive($id: String!, $active: Boolean!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { active: $active }) {
        id
      }
    }
  `,
  route: 'update_users_by_pk',
  variables: { id, active },
})
