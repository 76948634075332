import styled from 'styled-components'
import React from 'react'

import Button from './Button'
import FlexBox from './FlexBox'
import { navigate } from 'gatsby-link'

// @ts-ignore
export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Box>
      <p className="text-xl m-0">Something bad happened.</p>

      <p className="text-m m-3">Our team has been alerted and we will work on a fix promptly.</p>

      <Button.m
        onClick={() => {
          navigate('/')
          resetErrorBoundary()
        }}
      >
        Return Home
      </Button.m>
    </Box>
  )
}

const Box = styled(FlexBox)`
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
