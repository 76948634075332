export enum SoftwareChargeType {
  CaseStorage = 'Case Storage',
  AiAssessment = 'AI Assessment',
  SupportedFullConsult = 'Supported Full Consult',
}

export const softwareChargeTypeForId = (id: number) => {
  switch (id) {
    case 1:
      return SoftwareChargeType.CaseStorage
    case 2:
      return SoftwareChargeType.SupportedFullConsult
    case 3:
      return SoftwareChargeType.AiAssessment
  }
}
