import { createSlice } from '@reduxjs/toolkit'

import callHasura from '../callHasura'
import { ConsultationTypes_consultation_types } from '../graphQlQueries/types/ConsultationTypes'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { fetchUserAction } from './users'
import { user_role_consultation_types_insert_input } from '../../../types/globalTypes'

import {
  deleteUserRoleConsultationTypeQuery,
  fetchConsultationTypesQuery,
  insertUserRoleConsultationTypeQuery,
} from '../graphQlQueries/ConsultationTypes'

export interface ConsultationTypesState {
  isQuerying: any
  consultationTypes: ConsultationTypes_consultation_types[]
}

const initialState: ConsultationTypesState = {
  consultationTypes: [],
  isQuerying: {},
}

const consultationTypesSlice = createSlice({
  name: 'consultationTypes',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchConsultationTypesSuccess: (state, { payload }) => {
      state.consultationTypes = payload
    },
  },
})

export const { setLoading, networkingFailure, networkingSuccess, fetchConsultationTypesSuccess } = consultationTypesSlice.actions

export const consultationTypesSelector = (state: any) => state.consultationTypes

export default consultationTypesSlice.reducer

export function fetchConsultationTypesAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const consultationTypes: ConsultationTypes_consultation_types[] = await callHasura(accessToken, fetchConsultationTypesQuery())
      dispatch(fetchConsultationTypesSuccess(consultationTypes))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertUserRoleConsultationTypeAction(
  accessToken: string,
  object: user_role_consultation_types_insert_input,
  userId: string
) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertUserRoleConsultationTypeQuery(object))
      await dispatch(fetchUserAction(accessToken, userId))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deleteUserRoleConsultationTypeAction(accessToken: string, id: number, userId: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteUserRoleConsultationTypeQuery(id))
      await dispatch(fetchUserAction(accessToken, userId, false))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
