import gql from 'graphql-tag'

export const CASE_QUESTION_FIELDS = gql`
  fragment case_question_fields on case_questions {
    id
    case_questions_denormalized
    case_responses_denormalized
    completed_at
    created_at
    exclude_vet_ids_denormalized
    vet_id
  }
`
